import React from 'react'
import { SQL } from '@jelly/templates'

import Canon from '../../../../images/lp/wide-format-printers-plotters/v2/Canon-Partner-Logo.png'
import Epson from '../../../../images/lp/wide-format-printers-plotters/v2/Epson-Partner-Logo.png'
import HP from '../../../../images/lp/wide-format-printers-plotters/v2/HP-Partner-Logo.png'
import Konica from '../../../../images/lp/wide-format-printers-plotters/v2/Konica-Minolta-Partner-Logo.png'
import Roland from '../../../../images/lp/wide-format-printers-plotters/v2/Roland-Partner-Logo.png'
import Toshiba from '../../../../images/lp/wide-format-printers-plotters/v2/Toshiba-Partner-Logo.png'

import printer1 from '../../../../images/lp/wide-format-printers-plotters/v2/printer1.png'
import printer2 from '../../../../images/lp/wide-format-printers-plotters/v2/printer2.png'
import printer3 from '../../../../images/lp/wide-format-printers-plotters/v2/printer3.png'

const PrinterV2MetaData = {
	description:
		"Answer a few questions about your purchase, we'll match you with pre-screened sellers, and receive customized Wide Format Printers and Plotters price quotes today.",
	robots: 'noindex, nofollow',
	title: 'Wide Format Printers and Plotters Price Quotes From Qualified Sellers ',
	canonical: `${process.env.GATSBY_SITE_HOST}/lp/wide-format-printers-and-plotters/v2/`,
}

const HEADER_DATA = {
	bannerText: `Fast and free. Receive up to 5 quotes from Wide Format Printer brands by answering a few simple questions.`,
	taglineText: `SMART BUSINESS PURCHASES DELIVERED`,
	headerText: `Wide Format Printers and Plotters`,
	captionText: `Compare competitive price quotes from multiple wide format printer and plotter dealers so you know you're getting a great deal.`,
}

const FORM_DATA = {
	titleText: 'Is a traditional printer no longer cutting it?',
	bodyText: 'Find a printer that can handle large documents and materials. We work with brands who offer:',
	bullets: ['Wireless printers', 'Compactable printers', 'Fast speed printers', 'Photo printers', 'And more!'],
	widgetCategoryId: '10178',
}

const PACKAGES_DATA = {
	packagesTitle: 'Wide Format Printer and Plotter Options',
	context: [
		{
			titleText: 'Small Business',
			productName: 'Epson SureColor T2170',
			bullets: ['Sub-$1,000 price tag', 'Desktop-friendly size', 'Very lightweight model'],
			img: printer1,
		},
		{
			titleText: 'Detailed Applications',
			productName: 'HP DesignJet T830',
			bullets: ['High-res printing capability', 'Mobile device compatible', 'Fast printing'],
			img: printer2,
		},
		{
			titleText: 'Outdoor Signage',
			productName: 'Canon Colorado 1650',
			bullets: ['Uses eco-solvent UVgel', 'Great for outdoor prints', 'Unmatched production quality'],
			img: printer3,
		},
	],
}

const BRAND_DATA = [
	{ img: Canon, name: 'Canon' },
	{ img: Epson, name: 'Epson' },
	{ img: HP, name: 'HP' },
	{ img: Konica, name: 'Konica' },
	{ img: Roland, name: 'Roland' },
	{ img: Toshiba, name: 'Toshiba' },
]

const BUYING_GUIDE_DATA = [
	{
		headerText: 'How to Choose a Wide Format Printer and Plotter',
		context: [
			{
				paragraph: `Purchasing a wide-format printer for your small business is a big decision. These machines often require a lot of real estate in an office and a large financial commitment, whether it's from a single lump payment or through other financing options.`,
			},
			{
				paragraph: `When you choose a wide-format printer to purchase or lease, the most important thing to consider is your potential use and your business's needs. If your company only needs to print high-quality posters at a maximum of 24 inches, you probably shouldn't finance a $70,000 wide-format printer that can print on vinyl or fabric. Naturally, the same concept works in reverse. Knowing what you need out of a printer will be integral in ensuring you don't waste office space or money.`,
			},
			{
				paragraph: `Here are some other things you should consider when choosing a wide-format printer.`,
			},
			{
				subHeader: 'Key Characteristics:',
			},
			{
				bullets: [
					`Color spectrum. How your completed prints will look is incredibly important. One way you can make sure your work comes out exactly as you intended is to ensure that your printer and accompanying software use the right color spectrum. The two common spectrums are CMYK (cyan, magenta, yellow, black) and RGB (reg, green, blue). Certain printers prefer one over the other, so if color accuracy is important to you, this is something you should consider.`,
					`Ink type. There are several ink types on the market, and some printers use only certain types. As we discussed above, aqueous, pigment-based, UV and latex inks are good for different uses. If you know how you want to use the printer, you can select one that uses the best ink type for the projects you have in mind.`,
					`Size and weight. These machines can get big and heavy. If a wide-format printer is absolutely what you need for your office, size is an important consideration. Your office space is limited, so measure the space you have for the printer and compare it to the specs. If you plan on moving the machine around, make sure it doesn't weigh nearly a ton. Print speed. Time is money, so you can save on costs in the long run by selecting a fast wide-format printer. How quickly a printer functions depends on many things, but most manufacturers publish an estimated speed, so look for a printer that can put out a high number of square feet or pages per minute.`,
					`Print speed. Time is money, so you can save on costs in the long run by selecting a fast wide-format printer. How quickly a printer functions depends on many things, but most manufacturers publish an estimated speed, so look for a printer that can put out a high number of square feet or pages per minute.`,
				],
			},
		],
	},
	{
		headerText: 'Frequently Asked Questions about Wide Format Printers and Plotters',
		context: [
			{
				subHeader: `Who uses wide-format printers?`,
			},
			{
				paragraph: `There are many uses for wide-format printers, with a multitude of products that can be made thanks to them. For example, architects rely on these printers to create high-quality blueprints and site designs for projects, while photographers use wide-format printers to create massive, high-resolution prints of their work.`,
			},
			{
				paragraph: `The most common use of wide-format printers is in the marketing space. Thanks to these machines, marketers can get large, colorful advertisements out into the public square quickly and relatively affordably. If you see large signs, banners, or promotional window advertising, you've probably seen the fruits of a wide-format printer.`,
			},
			{
				subHeader: `Which types of media can wide-format printers print on?`,
			},
			{
				paragraph: `A large-format printer can be a huge boon for your business – in the right circumstances. If you run a printing shop, for example, the benefit is immediately obvious. However, other uses are equally beneficial for certain businesses. The benefits depend largely on your need for the machine.`,
			},
			{
				paragraph: `TProduction companies use a wide-format printer's vinyl application function to create colorful, realistic backgrounds without the need to construct physical sets. If your company exhibits at trade shows or produces collateral items for its customers, having your own wide-format printer allows you to create high-quality banners and signage. As long as the cost doesn't outweigh the machine's usefulness, a wide-format printer is a strong tool in a business's arsenal.`,
			},
			{
				subHeader: `How can a large-format printer benefit your business?`,
			},
			{
				paragraph: `A large-format printer can be a huge boon for your business – in the right circumstances. If you run a printing shop, for example, the benefit is immediately obvious. However, other uses are equally beneficial for certain businesses. The benefits depend largely on your need for the machine.`,
			},
			{
				paragraph: `Production companies use a wide-format printer's vinyl application function to create colorful, realistic backgrounds without the need to construct physical sets. If your company exhibits at trade shows or produces collateral items for its customers, having your own wide-format printer allows you to create high-quality banners and signage. As long as the cost doesn't outweigh the machine's usefulness, a wide-format printer is a strong tool in a business's arsenal.`,
			},
			{
				subHeader: `Is TIFF or JPEG better for printing?`,
			},
			{
				paragraph: `With a wide-format printer, it's always best to use the file type with the least compression, so you should use TIFF. Though they are usually considerably larger than their JPEG counterparts, TIFFs are lossless image files that offer higher-fidelity graphics with minimal compression.`,
			},
			{
				subHeader: `What color format is best for printing?`,
			},
			{
				paragraph: `As with most printed materials, it's best to use CMYK (cyan, magenta, yellow and black) as your color format. The other major format, RGB, is great for things like TVs and computer monitors, but CMYK prints by using different sizes of ink dots to display an image.`,
			},
			{
				subHeader: `What is an inkjet printer?`,
			},
			{
				paragraph: `Inkjet printers are a type of printing machine that effectively shoots tiny droplets of ink onto a piece of paper or other medium to display an image. Most home and small office printers are of the inkjet variety.`,
			},
			{
				subHeader: `Is inkjet or laser better for printing photos?`,
			},
			{
				paragraph: `When considering an inkjet or laser printer specifically for handling photos, you must remember how either works. While an inkjet printer sprays droplets of the appropriate inks at the right ratio to get the image onto the medium, a laser printer uses toner, meaning it's either only printing in monochrome or much more expensive for the color variant.`,
			},
			{
				paragraph: `TAs long as you use a coated piece of paper designed for photos, you should always see better results from inkjet machines, especially since they provide darker black levels and feature a much wider range in the color packet.`,
			},
		],
	},
]

const WideFormatPrintersAndPlottersPageV2 = () => {
	return (
		<>
			<SQL
				meta={PrinterV2MetaData}
				headerData={HEADER_DATA}
				formData={FORM_DATA}
				packagesData={PACKAGES_DATA}
				brandData={BRAND_DATA}
				buyingGuideData={BUYING_GUIDE_DATA}
			/>
		</>
	)
}

export default WideFormatPrintersAndPlottersPageV2
